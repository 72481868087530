import React, { useState, useEffect, useRef } from "react";
import { Box, Text, Button, Image, HStack, Pressable, Center } from "native-base";
import { useNavigate } from 'react-router-dom'; 

import AsyncStorage from "@react-native-async-storage/async-storage";

import { MdAccountCircle, MdImageSearch, MdCameraFront } from "react-icons/md";

import useCamera from './UseCamera';

import { api } from './api';

export default function Profile() {
  
  var [xiduser,setXiduser] = useState('');
  var [xname,  setXname]   = useState('');
  var [xphoto, setXphoto]  = useState('');
  var [photo, setPhoto]    = useState(null);
  var [file, setFile]    = useState(null);

  const navigate = useNavigate(); 
  const { videoRef, startCamera, stopCamera } = useCamera();

  const canvasRef = useRef(null); // Referência para o canvas
  const [image, setImage] = useState(null); // Estado para a imagem capturada  

  function irParaLogin() {
    navigate('/login');
  }

  async function loadStorageData() {
      
    setXiduser(await AsyncStorage.getItem('xiduser'));
    setXname( await AsyncStorage.getItem('xname'));


    //const a1 = 'https://silvi4210.c44.integrator.host/public/';
    const a1 = await AsyncStorage.getItem('xurl');
    const a2 = await AsyncStorage.getItem('xphoto');
    const a3 = a1 + a2 + "?new=" + Math.random();//.toString(36);
    
    setXphoto(a3);
    setPhoto(await AsyncStorage.getItem('xphoto'));
    
  }

  useEffect(() => {

    loadStorageData();
   
  }, []);

  async function irParaSelecionarFoto(event) {

    const files = event.target.files; // Obtém os arquivos do input
    if (files && files.length > 0) {
      const file = files[0]; // Seleciona o primeiro arquivo

      const objectURL = URL.createObjectURL(file); // Cria um Object URL
      setImage(objectURL); // Atualiza o estado com o Object URL
      setXphoto(objectURL);
      setFile(file); // Atualiza o estado com o arquivo original se já for JPEG

      const iditem = await AsyncStorage.getItem('xiduser');
  
      const renamedFile = new File([file], `avatar${iditem}.jpeg`, { type: file.type }); 

      const userPhotoUploadForm = new FormData();
      userPhotoUploadForm.append('upload',renamedFile);
  
      const headers = {
        'headers': {
          'Content-Type': 'multipart/form-data'
        }
      }
  
      const avatarUpdatedResponse = 
      await api.post("upload", userPhotoUploadForm, headers);
  
      const data = {
        photo : avatarUpdatedResponse.data
      }    
  
 
      try {
        await api.put(`user/avatar/${iditem}`,data);
                         
      } catch (err) {
         console.log(err);
      }
  
    }

  }


async function capturarImage() {
  await captureImage();
  stopCamera();
}
  

  async function captureImage() {
    if (videoRef.current && canvasRef.current) {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Configura o tamanho do canvas para corresponder ao vídeo
    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    // Desenha o frame atual do vídeo no canvas
    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    // Converte o conteúdo do canvas para uma URL de imagem
    const imageData = canvas.toDataURL('image/jpeg');
    setImage(imageData); // Salva a imagem no estado

    // daqui pra baixo repetir
    setXphoto(imageData);

    const iditem = await AsyncStorage.getItem('xiduser');

    // Converte a imagem capturada (base64) para um Blob
    const blob = await fetch(imageData).then((res) => res.blob());

    // Criar o arquivo para o envio
    const photoFile = new File([blob], `avatar${iditem}.jpeg`, { type: 'image/jpeg' });
  

    const userPhotoUploadForm = new FormData();
    userPhotoUploadForm.append('upload',photoFile);

    const headers = {
      'headers': {
        'Content-Type': 'multipart/form-data'
      }
    }

    const avatarUpdatedResponse = 
    await api.post("upload", userPhotoUploadForm, headers);

    const data = {
      photo : avatarUpdatedResponse.data
    }    

    console.log('iditem');
    console.log(iditem);
    
    console.log('data');
    console.log(data);

    try {
      await api.put(`user/avatar/${iditem}`,data);
                       
    } catch (err) {
       console.log(err);
    }

  }
};



  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <Text fontSize="2xl">Tela Profile</Text>
      <Button onPress={irParaLogin} mt={4}>
        Ir para a Tela Login
      </Button>

      {photo?
          <Image 
            mt={4}
            w={50} 
            h={50} 
            rounded='full' 
            borderWidth={2} 
            borderColor='gray.400' 
            alt="foto"
            key={Date.now()} 
            source={{uri:xphoto}}
          />
          :<MdAccountCircle mt={4} style={{ color: 'gray', fontSize: '42px' }} /> 
      }

      <HStack mt={4}>

      {/* <Pressable     
        onPress={irParaSelecionarFoto}
      >

        <MdImageSearch />
        
      </Pressable> */}

      <input
        id="fileInput"
        type="file"
        accept="image/*"
        onChange={irParaSelecionarFoto} // Manipula a seleção de arquivo
      />


      <Pressable     
        onPress={startCamera}
      >

        <MdCameraFront />
        
      </Pressable>


     


      </HStack>  

      <Center>

      <Box style={{ color: 'gray'}}>
        <video
          ref={videoRef}
          style={{
            width: '50px',
            height: '50px',
            backgroundColor: 'silver',
            // borderRadius: '10px',
          }}
        />
      </Box>

      </Center>

      <Button mt={4} onPress={capturarImage}>
        Capturar Imagem
      </Button>

      {/* <Button mt={4} onPress={stopCamera}>
        Desligar Câmera
      </Button> */}

      {/* Canvas oculto para capturar a imagem */}
      <canvas ref={canvasRef} style={{ display: 'none' }} />

      {/* Mostra a imagem capturada */}
      {/* {image && (
        <Box mt={4}>
          <Image
            source={{ uri: image }}
            alt="Imagem capturada"
            style={{ width: 300, height: 300 }}
          />
        </Box>
      )} */}

    </Box>
  );
}