import React from "react";
import { NativeBaseProvider, Box } from "native-base";
import Rotas from './Rotas';

export default function App() {
  return (
    <NativeBaseProvider>
      <Rotas /> 
    </NativeBaseProvider>
  );
}