// import { useRef } from 'react';

// const useCamera = () => {
//   const videoRef = useRef(null);

//   const startCamera = async () => {
//     try {
//       const stream = await navigator.mediaDevices.getUserMedia({ video: true });
//       if (videoRef.current) {
//         videoRef.current.srcObject = stream;
//         videoRef.current.play();
//       }
//     } catch (err) {
//       console.error('Erro ao acessar a câmera:', err);
//     }
//   };

//   return { videoRef, startCamera };
// };

// export default useCamera;


import { useRef } from 'react';

const useCamera = () => {
  const videoRef = useRef(null);
  const streamRef = useRef(null); // Para armazenar o fluxo de mídia

  const startCamera = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      streamRef.current = stream; // Armazena o fluxo no ref
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
        videoRef.current.play();
      }
    } catch (err) {
      console.error('Erro ao acessar a câmera:', err);
    }
  };

  const stopCamera = () => {
    if (streamRef.current) {
      // Para todas as tracks do stream
      streamRef.current.getTracks().forEach((track) => track.stop());
      streamRef.current = null; // Limpa o stream armazenado
    }
    if (videoRef.current) {
      videoRef.current.srcObject = null; // Remove o stream do vídeo
    }
  };

  return { videoRef, startCamera, stopCamera };
};

export default useCamera;
