import React from "react";
import { Box, Text, Button } from "native-base";
import { useNavigate } from 'react-router-dom'; 

export default function Principal() {
  
  const navigate = useNavigate(); 

  function irParaLogin() {
    navigate('/login');
  }

  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <Text fontSize="2xl">Tela Principal</Text>
      <Button onPress={irParaLogin} mt={4}>
        Ir para a Tela Login
      </Button>
    </Box>
  );
}