import React, { useState, useEffect } from "react";
import { Box, Text, Button, Image, Pressable } from "native-base";
import { useNavigate } from "react-router-dom"; 

import AsyncStorage from "@react-native-async-storage/async-storage";

import { MdAccountCircle } from "react-icons/md";

export default function Home() {
  
  var [xiduser,setXiduser] = useState('');
  var [xname,  setXname]   = useState('');
  var [xphoto, setXphoto]  = useState('');
  var [photo, setPhoto]    = useState(null);

  const navigate = useNavigate(); 

  function irParaLogin() {
    navigate("/login");
  }

  function irParaProfile() {
    navigate('/profile');
  };

  async function loadStorageData() {
      
    setXiduser(await AsyncStorage.getItem('xiduser'));
    setXname( await AsyncStorage.getItem('xname'));


    //const a1 = 'https://silvi4210.c44.integrator.host/public/';
    const a1 = await AsyncStorage.getItem('xurl');
    const a2 = await AsyncStorage.getItem('xphoto');
    const a3 = a1 + a2 + "?new=" + Math.random();//.toString(36);
    
    setXphoto(a3);
    setPhoto(await AsyncStorage.getItem('xphoto'));
    
  }

  useEffect(() => {

    loadStorageData();

   
  }, []);

  

  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      
      <Text fontSize="2xl">Tela Home</Text>
      

      <Pressable     
        onPress={irParaProfile}
      >

        {photo?
          <Image 
            w={50} 
            h={50} 
            rounded='full' 
            borderWidth={2} 
            borderColor='gray.400' 
            alt="foto"
            key={Date.now()} 
            source={{uri:xphoto}}
          />
          :<MdAccountCircle style={{ color: 'gray', fontSize: '42px' }} /> 
        }

      </Pressable>

      <Text>Olá {xname}</Text>

      <Button onPress={irParaLogin} mt={4}>
        Ir para a Tela Login
      </Button>

    </Box>
  );
}