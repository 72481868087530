import { useState, React } from 'react';
import { Box, Text, Input, Button } from "native-base";
import { useNavigate } from 'react-router-dom'; 
import { api } from './api';

export default function Registro() {

  const [login,setLogin] = useState('');
  const [passwd,setPasswd] = useState('');
  const [confirm,setConfirm] = useState('');
  const [name,setName] = useState('');

  const navigate = useNavigate(); 

  
  function voltarParaLogin() {
    navigate('/login');
  }

  async function irGravarRegistro() {

    const data = {
      login: login,
      name: name,
      passwd:passwd
    }    

    console.log(data);

    // gravar em mysql silvio4210_bdteste.tbuser
    try {
      await api.post("user/new",data);
                      
      navigate('/login');

    } catch (err) {
       console.log(err);
    }

  }

  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <Text fontSize="2xl">Tela Registro</Text>
      <Button onPress={voltarParaLogin} mt={4} w={48}>
        Voltar para Tela Login
      </Button>

      <Text mt={4}>Email/Login</Text>

      <Input
            placeholder="Digite Login"
            variant="outline"
            size="md"
            value={login}
            onChangeText={setLogin} 
      />

      <Text mt={4}>Senha</Text>
      
      <Input
            placeholder="Digite uma Senha"
            variant="outline"
            size="md"
            value={passwd}
            onChangeText={setPasswd} 
            type="password"
      />   

      <Text mt={4}>Confirme a Senha</Text>
      
      <Input
            placeholder="Confirme a Senha"
            variant="outline"
            size="md"
            value={confirm}
            onChangeText={setConfirm} 
            type="password"
      />    

      <Text mt={4}>Nome</Text>

      <Input
            placeholder="Digite seu nome"
            variant="outline"
            size="md"
            value={name}
            onChangeText={setName} 
      />                  

      <Button onPress={irGravarRegistro} mt={4} w={48} >
        OK
      </Button>


    </Box>
  );
}
