import { useState, React } from 'react';
import { Box, Text, Input, Button, useToast } from "native-base";
import { useNavigate } from 'react-router-dom'; 
import { api } from './api';
import AsyncStorage from "@react-native-async-storage/async-storage";

export default function Login() {

  const [login,setLogin] = useState('ssilman@gmail.com');
  const [passwd,setPasswd] = useState('123');
  
  const navigate = useNavigate(); 

  const toast = useToast();

  function voltarParaPrincipal() {
    navigate('/');
  }

  async function irLogar() {



    const data = {
      login: login,
      passwd: passwd
    }    

    console.log(data);

    try {
      const response = await api.post("user/login", data);
      
      if (response.data[0]) {

        const xiduser = response.data[0].iduser.toString();
        const xlogin  = response.data[0].login;
        const xname   = response.data[0].name;
        const xphoto  = response.data[0].photo;

        toast.show({
          title: "Autenticação",
          status: "success", // "success", "error", "warning", "info"
          description: "Olá! Você está autenticado!",
          duration: 3000, // Tempo em milissegundos antes de desaparecer
          placement: "top", // "top", "bottom", "top-left", etc.
        });        

        AsyncStorage.setItem('xiduser',xiduser);
        AsyncStorage.setItem('xlogin',xlogin);
        AsyncStorage.setItem('xname',xname);
        AsyncStorage.setItem('xphoto',xphoto);
        AsyncStorage.setItem('xurl','https://silvi4210.c44.integrator.host/public/');

        navigate('/home');

      } else {

        toast.show({
          title: "Você não está autenticado!",
          status: "error", // "success", "error", "warning", "info"
          description: "Login e senha não cadastrados.",
          duration: 3000, // Tempo em milissegundos antes de desaparecer
          placement: "top", // "top", "bottom", "top-left", etc.
        });

      }

    } catch (err) {

      toast.show({
        title: "Erro!",
        status: "error", // "success", "error", "warning", "info"
        description: "Erro na api de autenticacao.",
        duration: 3000, // Tempo em milissegundos antes de desaparecer
        placement: "top", // "top", "bottom", "top-left", etc.
      });

    }
  }

  function irParaRegistro() {
    navigate('/registro');
  }

  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <Text fontSize="2xl">Tela Login</Text>
      <Button onPress={voltarParaPrincipal} mt={4} w={48}>
        Voltar para Tela Principal
      </Button>

      <Text mt={4}>Email/Login</Text>

      <Input
            placeholder="Digite Login"
            variant="outline"
            size="md"
            value={login}
            onChangeText={setLogin} 
        />

      <Text mt={4}>Senha</Text>
      
      <Input
            placeholder="Digite Senha"
            variant="outline"
            size="md"
            value={passwd}
            onChangeText={setPasswd} 
            type="password"
        />      

      <Button onPress={irLogar} mt={4} w={48} >
        OK
      </Button>


      <Button
            onPress={irParaRegistro}
            mt={4}
            w={48}
            bg="white"
            borderWidth={1}
            borderColor="black"
            _hover={{
                bg: "green.700", // Fundo preto ao passar o mouse
                borderColor: "black", // Borda preta
                _text: {
                    color: "white", // Texto branco ao passar o mouse
                },
            }}
            _text={{
                color: "black", // Texto preto por padrão
            }}
        >
            Novo Cadastro
        </Button>

    </Box>
  );
}
