import React from 'react';
import { BrowserRouter, Route, Routes} from 'react-router-dom';
import Principal from './Principal';
import Login from './Login';
import Registro from './Registro';
import Home from './Home';
import Profile from './Profile';

export default function Rotas() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" exact element={<Principal />} />
        <Route path="/login" element={<Login />} />
        <Route path="/registro" element={<Registro />} />
        <Route path="/home" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
      </Routes>
    </BrowserRouter>
  )  
}